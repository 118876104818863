import styled from 'styled-components';

export const Container = styled.div`
  height: 150px;

  .left {
    width: 70%;
    box-sizing: border-box;
    padding: 30px 30px;
    white-space: nowrap;
  }

  .right {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

    .containerIcon {
      font-size: 50px;
      color: white;
    }
  }

  .cardContainer {
    padding-right: 0px;
    padding-left: 0px;
    min-height: 140px;
    max-height: 200px;
  }

  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px !important;
    cursor: pointer;

    svg {
      margin-left: 5px;
    }
  }

  .text-muted {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.125);

    &:hover {
      color: #fff !important;
      background: rgba(0, 0, 0, 0.15) !important;
    }
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .footerDisabled {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
  }
`;
