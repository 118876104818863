import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import React from 'react';
import { useHistoricoProduto } from '~/components/HistoricoProduto/HistoricoProdutoContext';

interface DataGridEntradaSaidaProps {
  rows: readonly GridRowData[] | null;
  columns: GridColDef[];
  height?: string;
  hidePaginacao?: boolean;
}

export const DataGridEntradaSaidaCustom: React.FC<
  DataGridEntradaSaidaProps
> = ({ rows, columns, height = '31.25rem', hidePaginacao = false }) => {
  const { isFetchingTable } = useHistoricoProduto();

  return (
    <>
      {hidePaginacao && (
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          disableColumnMenu
          loading={isFetchingTable}
          style={{ minHeight: height, maxHeight: height }}
          disableSelectionOnClick
          hideFooterSelectedRowCount
          hideFooterRowCount={hidePaginacao}
          hideFooterPagination={hidePaginacao}
          hideFooter={hidePaginacao}
          localeText={{
            noRowsLabel: 'Nenhum registro encontrado...',
            columnMenuLabel: 'Menu',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Esconder',
            columnMenuUnsort: 'Não ordenar',
            columnMenuSortAsc: 'Ordernar ASC',
            columnMenuSortDesc: 'Ordernar DESC',
            columnMenuShowColumns: 'Mostrar colunas',
          }}
        />
      )}
      {!hidePaginacao && (
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          disableColumnMenu
          loading={isFetchingTable}
          style={{ minHeight: height, maxHeight: height }}
          disableSelectionOnClick
          pageSize={10}
          localeText={{
            noRowsLabel: 'Nenhum registro encontrado...',
            columnMenuLabel: 'Menu',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Esconder',
            columnMenuUnsort: 'Não ordenar',
            columnMenuSortAsc: 'Ordernar ASC',
            columnMenuSortDesc: 'Ordernar DESC',
            columnMenuShowColumns: 'Mostrar colunas',
          }}
        />
      )}
    </>
  );
};
