export const arredondarParaMultiploDeCinco = (numero: number): number => {
  const resto = numero % 5;
  let resultado: number;

  if (resto === 0) {
    resultado = numero;
  } else {
    resultado = numero + (5 - resto);
  }

  return resultado;
};
