import React, { useState, useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import { BsBox, BsGraphUp, BsArrowRightCircle } from 'react-icons/bs';
import { RiCalendar2Line } from 'react-icons/ri';
import { MdOutlineHistory } from 'react-icons/md';
import { Container } from './styles';
import { useHistoricoProduto } from '../../HistoricoProdutoContext';

interface CardProps {
  title: string;
  titleContent: string;
  content: string;
  footerText?: string;
  setIsOpenExtrato?: (value: boolean) => void;
  setLabelExtrato?: (value: string) => void;
}

export const CardSuperiors: React.FC<CardProps> = (props) => {
  const { title, titleContent, content, footerText, setLabelExtrato } = props;

  const { handleIsOpenExtrato, handleCardClick, handleTitleExtrato } =
    useHistoricoProduto();
  const [color, setColor] = useState('');
  const [labelExtratoCard, setLabelExtratoCard] = useState('');

  const openCard = () => {
    if (footerText) {
      handleIsOpenExtrato(true);
      handleCardClick(title);
      handleTitleExtrato(title);
      if (setLabelExtrato) {
        setLabelExtrato(labelExtratoCard);
      }
    }
  };

  useEffect(() => {
    switch (title) {
      case 'estoque':
        setColor('#00a65a');
        setLabelExtratoCard('Extrato - Movimentação de Estoque');
        break;
      case 'venda':
        setColor('#00c0ef');
        setLabelExtratoCard('Extrato de Vendas');
        break;
      case 'lucro':
        setColor('#dd4b39');
        break;
      case 'media':
        setColor('#f39c12');
        break;
      default:
        break;
    }
  }, [title]);

  return (
    <Container>
      <Row style={{ marginLeft: '0px' }}>
        <Card
          style={{
            backgroundColor: color,
          }}
          className="cardContainer"
        >
          <Card.Body
            style={{
              display: 'flex',
              padding: '0px',
            }}
          >
            <div className="left" style={{ marginTop: '10px', color: 'white' }}>
              <Card.Title>{titleContent}</Card.Title>
              <Card.Text>{content}</Card.Text>
            </div>
            <div className="right">
              <Card.Text style={{ color: 'white' }}>
                {title === 'estoque' && <BsBox className="containerIcon" />}
                {title === 'venda' && (
                  <MdOutlineHistory className="containerIcon" />
                )}
                {title === 'lucro' && <BsGraphUp className="containerIcon" />}
                {title === 'media' && (
                  <RiCalendar2Line className="containerIcon" />
                )}
              </Card.Text>
            </div>
          </Card.Body>
          <Card.Footer
            className={`${footerText ? 'text-muted' : 'footerDisabled'}`}
            onClick={(event: any) => {
              event.stopPropagation();
              openCard();
            }}
            style={{ cursor: `${footerText ? 'pointer' : 'inherit'}` }}
          >
            {footerText && (
              <>
                {footerText} <BsArrowRightCircle />
              </>
            )}
          </Card.Footer>
        </Card>
      </Row>
    </Container>
  );
};
