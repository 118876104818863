import styled from 'styled-components';

export const ContainerTableExtrato = styled.div`
  width: 100%;
  height: 100%;
  .hideRightSeparator .MuiDataGrid-columnSeparator {
    display: none;
  }

  .w-33 {
    min-width: 33.33% !important;
    max-width: 33.33% !important;
  }
`;

export const ContainerEntradaSaida = styled.div`
  width: 100%;
  height: 100%;

  .nav-link.active {
    color: #57069e !important;
    font-weight: 600;
  }

  .nav-link {
    color: #666666 !important;
    font-weight: 400;
  }

  .hideRightSeparator .MuiDataGrid-columnSeparator {
    display: none;
  }
`;
