import React, { useContext, useEffect, useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { v4 } from 'uuid';
import { ThemeContext } from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { useHistoricoProduto } from '../../HistoricoProdutoContext';
import { GridColDefCustomProps } from '../../protocols';
import { Totalizadores } from '../Totalizadores';
import { formatCurrency } from '../../function/FormatCurrency';
import { ColumnSeparator } from '~/components/NovosInputs/CustomDataGrid/styles';

interface FormProps {
  rows: any[];
  columns: GridColDefCustomProps[];
  hasTitle?: boolean;
  minHeight?: string;
  maxHeight?: string;
  errorMessage?: string;
  totalizador?: boolean;
}

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const {
    rows,
    columns,
    minHeight = '400px',
    maxHeight = '400px',
    errorMessage = 'Nenhum registro encontrado',
    totalizador = false,
  } = props;

  const { colors } = useContext(ThemeContext);

  const {
    limitExtrato,
    handlePagetExt,
    handleLimitExt,
    titleExtrato,
    isFetchingTable,
  } = useHistoricoProduto();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsHeight, setRowsHeight] = useState<any>('');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    handlePagetExt(newPage === 0 ? 1 : newPage + 1);
    handleLimitExt(rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    handlePagetExt(1);
    handleLimitExt(+event.target.value);
    setPage(0);
  };

  function formatType(item: any, type: string | undefined) {
    if (type === 'money') {
      return new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
      }).format(item);
    }
    if (type === 'qtd') {
      return formatCurrency(item, 3);
    }

    if (type === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
      }).format(item);
    }
    return item;
  }

  const convertPixelToRem = (altura: any) => {
    const fontSizePx = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );
    if (altura) {
      // Calcula a altura em rem
      return altura / fontSizePx;
    }
  };

  const rowHeight = () => {
    // Calcula altura da row quando resolução deixar espaco em branco no extrato
    if (
      rowsPerPage === 10 &&
      limitExtrato > rowsPerPage &&
      rows.length === rowsPerPage
    ) {
      const bodyGridAlt = document.getElementById('bodyGrid')?.clientHeight;
      const bodyGridAltura = convertPixelToRem(bodyGridAlt);
      if (bodyGridAltura) {
        if (bodyGridAltura < parseFloat(minHeight)) {
          setRowsHeight(`${parseFloat(minHeight) / 10 - 0.4}rem`);
        }
      }
    } else {
      setRowsHeight('');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      rowHeight();
    }, 100);
  }, [minHeight]);

  return (
    <Paper>
      <TableContainer
        style={{
          height: '100%',
          maxHeight,
          minHeight: minHeight === '0rem' ? '300px' : minHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: `${rows.length > 0 ? 'space-between' : ''}`,
        }}
      >
        <Table stickyHeader>
          <TableHead style={{ position: 'sticky', top: '0', zIndex: '2' }}>
            <TableRow style={{ display: 'flex' }}>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={v4()}
                    style={{
                      minWidth: `${column.width}`,
                      display: 'flex',
                      justifyContent: column.headerAlign,
                    }}
                  >
                    {column.headerName}

                    {!column.hideRightSeparator && (
                      <ColumnSeparator>
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>
                      </ColumnSeparator>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody id="bodyGrid">
            {rows.length > 0 &&
              !isFetchingTable &&
              rows.map((row) => {
                return (
                  <TableRow
                    id="rowGrid"
                    style={{
                      display: 'flex',
                      alignItems: rowsHeight !== '' ? 'center' : '',
                      height:
                        rowsHeight === '' && parseFloat(rowsHeight) <= 2
                          ? 'inherit'
                          : rowsHeight,
                    }}
                    hover
                    tabIndex={-1}
                    key={v4()}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell
                          style={{
                            display: 'flex',
                            minWidth: `${column.width}`,
                            justifyContent: column.align,
                          }}
                          key={v4()}
                        >
                          {formatType(row[column.field], column.format)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {isFetchingTable && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <ClipLoader color={colors.primary} />
          </div>
        )}

        {!isFetchingTable && rows.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <p>{errorMessage}</p>{' '}
          </div>
        )}

        {rows.length > 0 && totalizador && <Totalizadores />}
      </TableContainer>

      {titleExtrato === 'estoque' && (
        <TablePagination
          id="pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={limitExtrato}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {titleExtrato === 'tabelaPreco' && (
        <TablePagination
          id="pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={limitExtrato}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {titleExtrato === 'tabelaCusto' && (
        <TablePagination
          id="pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={limitExtrato}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default CustomDataGrid;
