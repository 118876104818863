import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';
import { useHistoricoProduto } from '../../HistoricoProdutoContext';
import { formatCurrency } from '../../function/FormatCurrency';

export const Totalizadores: React.FC = () => {
  const { qtdTotalVenda, totalVenda } = useHistoricoProduto();

  return (
    <TableRow
      style={{
        display: 'flex',
        position: 'sticky',
        bottom: '-1px',
        backgroundColor: 'rgb(241, 241, 241)',
      }}
      key={1}
    >
      <TableCell
        style={{
          width: '33%',
        }}
      />
      <TableCell
        style={{
          textAlign: 'center',
          backgroundColor: 'rgb(229, 229, 229)',
          fontWeight: 700,
          width: '33%',
        }}
      >
        {formatCurrency(qtdTotalVenda, 3)}
      </TableCell>

      <TableCell
        style={{
          textAlign: 'right',
          backgroundColor: 'rgb(229, 229, 229)',
          fontWeight: 700,
          width: '33%',
        }}
      >
        {formatCurrency(totalVenda, 2)}
      </TableCell>
    </TableRow>
  );
};
