export const somarArrays = (arr1: number[], arr2: number[]): number => {
  if (arr1.length === 0 || arr2.length === 0) {
    const array = arr1.length === 0 ? arr2 : arr1;
    return array.reduce((acc, curr) => acc + curr, 0);
  }

  if (arr1.length !== arr2.length) {
    throw new Error('Os arrays têm comprimentos diferentes.');
  }

  let somaTotal = 0;

  for (let i = 0; i < arr1.length; i++) {
    somaTotal += arr1[i] + arr2[i];
  }

  return somaTotal;
};
