import ApexCharts from 'apexcharts';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ClipLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';

import { ContainerFlex, ContainerLoader } from './styles';
import { somarArrays } from './utils/somarArrays';
import { processarObjetos } from './utils/processarObjetos';
import { maiorValor } from './utils/maiorValor';
import { arredondarParaMultiploDeCinco } from './utils/arredondarParaMultiploDeCinco';

interface GraficoProps {
  movimentacoes: any;
  months: string[];
}

export const Grafico: React.FC<GraficoProps> = (props) => {
  const { colors } = useContext(ThemeContext);
  const { movimentacoes, months } = props;
  const [chart, setChart] = useState<ApexCharts | undefined>(undefined);
  const [loadGraph, setLoadGraph] = useState<boolean>(true);

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: 'bar',
      height: 235,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: months,
    },
    yaxis: {
      labels: {
        formatter(value: any) {
          return (value || '0')?.toString().replace('.', ',');
        },
      },
    },
    series: [
      {
        name: 'Entrada',
        data: [],
        color: '#dd4b39',
      },
      {
        name: 'Saída',
        data: [],
        color: '#00a65a',
      },
    ],
  });

  const SortSaidaEntrada = (value: any) => {
    value.sort(function (a: any, b: any) {
      const aDate: any = new Date(
        parseInt(`20${a.mes_ano.split('/')[1]}`, 10),
        parseInt(a.mes_ano.split('/')[0], 10) - 1,
      );
      const bDate: any = new Date(
        parseInt(`20${b.mes_ano.split('/')[1]}`, 10),
        parseInt(b.mes_ano.split('/')[0], 10) - 1,
      );
      return aDate - bDate;
    });

    return value;
  };

  const processaArraySaidaEntrada = useCallback(
    (newSaida: any[], newEntrada: any[]) => {
      const somaArray = somarArrays(
        processarObjetos(newSaida),
        processarObjetos(newEntrada),
      );

      const arrSaida = processarObjetos(newSaida);
      const arrEntrada = processarObjetos(newEntrada);

      const somaArraySaida = somarArrays(processarObjetos(newSaida), []);
      const somaArrayEntrada = somarArrays(processarObjetos(newEntrada), []);

      return {
        somaArray,
        somaArraySaida,
        somaArrayEntrada,
        arrSaida,
        arrEntrada,
      };
    },
    [],
  );

  const orderSaidaEntrada = useCallback(
    (saida: any, entrada: any) => {
      setLoadGraph(true);

      const newSaida = SortSaidaEntrada(saida);
      const newEntrada = SortSaidaEntrada(entrada);

      const { arrSaida, arrEntrada, somaArraySaida, somaArrayEntrada } =
        processaArraySaidaEntrada(newSaida, newEntrada);

      const maxValue = arredondarParaMultiploDeCinco(
        maiorValor(somaArraySaida, somaArrayEntrada),
      );

      // atualizar chart
      const newChartOptions = { ...chartOptions };

      newChartOptions.yaxis = {
        max: maxValue,
        show: false,
      };

      newChartOptions.series = [
        {
          name: 'Saída',
          data: arrSaida,
          color: '#dd4b39',
        },
        {
          name: 'Entrada',
          data: arrEntrada,
          color: '#00a65a',
        },
      ];
      setChartOptions(newChartOptions);

      setLoadGraph(false);
    },
    [chartOptions, processaArraySaidaEntrada],
  );

  useEffect(() => {
    const saida: any[] = [];
    const entrada: any[] = [];

    if (movimentacoes && movimentacoes[0] !== null) {
      movimentacoes.map((movimento: any) => {
        if (movimento.tipo_operacao === 'Saída') {
          saida.push(movimento);
        } else {
          entrada.push(movimento);
        }
        return false;
      });

      orderSaidaEntrada(saida, entrada);
    } else {
      setLoadGraph(false);
    }
  }, [movimentacoes]);

  useEffect(() => {
    if (!loadGraph) {
      if (!chart) {
        if (document.querySelector('#simple-chart')) {
          const newChart = new ApexCharts(
            document.querySelector('#simple-chart'),
            chartOptions,
          );
          if (newChart) {
            newChart.render();
            setChart(newChart);
          }
        }
      } else {
        chart?.updateOptions(chartOptions);
      }
    }
  }, [chart, chartOptions, loadGraph]);

  if (loadGraph) {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  }

  return (
    <>
      {movimentacoes && (
        <div id="simple-chart">
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="bar"
            height={235}
          />
        </div>
      )}
      {movimentacoes === null && (
        <ContainerFlex>
          <p>
            Não foi possível obter informações de entrada e saída desse produto
          </p>
        </ContainerFlex>
      )}
    </>
  );
};
